<template>
   <div class="">
      <div class="">
        <div class="page-banner">
          <img :src="this.getSiteProps('clubinnerpages.headerbanner')" style="width:100%;" alt="page-banner">
          <div class="page-title">
            <h5 class="text-white"> My Officials Ratings/Certifications</h5>
          </div>
        </div>

         <div class="row">
            <div class="col-md-9 mb-3 mt-4">

              <div class="col-md-12 mt-3 mb-3" v-if="subdomain == 'waterski'">
                <div v-if="getError" class="alert alert-danger">
                  Error Occurred while fetching the information; please contact administration.
                </div>
                <div class="accordion" role="tablist">
                  <b-card no-body class="mb-1">
                    <b-overlay :show="showLoader" no-wrap></b-overlay>
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-1 variant="info">Officials Ratings</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" style="overflow-x: auto" role="tabpanel">
                      <b-card-body class="pl-0 pr-0" v-if="displayOfficialRatings">
                        <button v-if="!getError" @click="downloadOfficials('ratings',memberInfoData.person_id)" class="btn btn-xs btn-download pull-right" :class="exportProgressBtn">
                          <span v-if="exportProgress" ><i class="fas fa-circle-notch fa-spin"></i> Downloading</span>
                          <span v-if="!exportProgress"><img src="/dist/img/profile/ico-download.png">PDF</span>
                        </button>
                        <button v-if="!getError" @click="downloadOfficialsAsExcel('ratings',memberInfoData.person_id)" class="btn btn-xs btn-download pull-right"  :class="exportProgressExcBtn">
                          <span v-if="exportProgressExc" ><i class="fas fa-circle-notch fa-spin"></i> Downloading</span>
                          <span v-if="!exportProgressExc"><img src="/dist/img/profile/ico-download.png">CSV</span>
                        </button>
                        <b-table
                            id="my-table"
                            :items="ratingData"
                            :fields="ratingTblFields"
                            hover
                            show-empty
                            :sort-by.sync="ratingSortBy"
                            :sort-desc.sync="ratingSortDesc"
                        >
                          <template #empty="scope">
                            <h4>{{ scope.emptyText }}</h4>
                          </template>
                          <template #cell(division)="row">
                            {{ row.item.Rating.split(": ")[2] }}
                          </template>
                          <template #cell(rating_type)="row">
                            {{ row.item.Rating.split(": ")[0] }}
                          </template>
                          <template #cell(level)="row">
                            {{ row.item.Rating.split(": ")[1] }}
                          </template>
                          <template #cell(EventAbbreviation)="row">
                            {{ eventName[row.value] }}
                          </template>
                          <template #cell(ExpirationDate)="row">
                            <span v-html="row.value">1</span>
                          </template>
                          <template #cell(Active)="row">
                            <span>{{ row.value==1?"No":"Yes" }}  </span>
                            <b-link :href='"/roster/ratings_details/"+row.item.PersonID+"/"+row.item.Event_ID+"/"+row.item.RatingType_ID'>Details</b-link>
                          </template>
                        </b-table>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-1">
                    <b-overlay :show="showLoader" no-wrap></b-overlay>
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-2 variant="info">Officials Certifications</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" style="overflow-x: auto" role="tabpanel">
                      <b-card-body class="pl-0 pr-0">
                        <button v-if="!getError" @click="downloadOfficials('certs',memberInfoData.person_id)" class="btn btn-xs btn-download pull-right" :class="exportProgressBtn">
                          <span v-if="exportProgress" ><i class="fas fa-circle-notch fa-spin"></i> Downloading</span>
                          <span v-if="!exportProgress"><img src="/dist/img/profile/ico-download.png">PDF</span>
                        </button>
                        <button v-if="!getError" @click="downloadOfficialsAsExcel('certs',memberInfoData.person_id)" class="btn btn-xs btn-download pull-right" :class="exportProgressExcBtn">
                          <span v-if="exportProgressExc" ><i class="fas fa-circle-notch fa-spin"></i> Downloading</span>
                          <span v-if="!exportProgressExc"><img src="/dist/img/profile/ico-download.png">CSV</span>
                        </button>
                        <b-table
                            id="my-table"
                            :items="certsData"
                            :fields="certsTblFields"
                            hover
                            show-empty
                            :sort-by.sync="certsSortBy"
                            :sort-desc.sync="certsSortDesc"
                        >
                          <template #empty="scope">
                            <h4>{{ scope.emptyText }}</h4>
                          </template>
                          <template #cell(data)="row">
                            <span v-html="row.value"></span>
                          </template>
                          <template #cell(status)="row">
                            {{ row.item.status === "Clear" ? "Complete" : row.item.status }}
                          </template>
                        </b-table>
                      </b-card-body>
                      <template v-if="driverRating">
                        <div class="form-group" v-if="age >= 16">
                        <label style="margin-left:10px"><strong>MVR Status :</strong>&nbsp;
                          <span class="text-success" v-if="mvr != null && mvr.status == 2 && mvr.background_screening_status_text != 'In Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ mvr.background_screening_status_text || "N/A"}}<span class="text-success" v-if="mvr.expiration != null"> (Expires {{ mvr.expiration | formatDob }})</span></span>
                          <span class="text-danger" v-else-if="(mvr != null && mvr.status == 7) || (mvr != null && mvr.status == 3)"><img style="width: 18px" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ mvr.background_screening_status_text || "N/A"}} (On {{ mvr.updated_at | formatDob }})</span>
                          <span class="text-warning" v-else-if="mvr != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{mvr.background_screening_status_text || "N/A"}}</span>
                          <span class="text-warning" v-else>Not Started</span>
                        </label>
                        </div>
                        <div class="form-group" v-else>
                          <label style="margin-left:10px"><strong>MVR Status :</strong></label>&nbsp;
                          <span class="">N/A</span>
                        </div>
                      </template>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-1">
                    <b-overlay :show="showLoader" no-wrap></b-overlay>
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-3 variant="info">Events</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" style="overflow-x: auto" role="tabpanel">
                      <b-card-body class="pl-0 pr-0">
                        <button v-if="!getError" @click="downloadOfficials('events',memberInfoData.person_id)" class="btn btn-xs btn-download pull-right" :class="exportProgressBtn">
                          <span v-if="exportProgress" ><i class="fas fa-circle-notch fa-spin"></i> Downloading</span>
                          <span v-if="!exportProgress"><img src="/dist/img/profile/ico-download.png">PDF</span>
                        </button>
                        <button v-if="!getError" @click="downloadOfficialsAsExcel('events',memberInfoData.person_id)" class="btn btn-xs btn-download pull-right" :class="exportProgressExcBtn">
                          <span v-if="exportProgressExc" ><i class="fas fa-circle-notch fa-spin"></i> Downloading</span>
                          <span v-if="!exportProgressExc"><img src="/dist/img/profile/ico-download.png">CSV</span>
                        </button>
                        <b-table
                            id="my-table"
                            :items="eventsData"
                            :fields="eventsTblFields"
                            hover
                            show-empty
                            :sort-by.sync="eventsSortBy"
                            :sort-desc.sync="eventsSortDesc"
                            v-if="displayOfficialRatings"
                        >
                          <template #empty="scope">
                            <h4>{{ scope.emptyText }}</h4>
                          </template>
                        </b-table>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-1" v-if="subdomain != 'waterski'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.accordion-4 variant="info">Officials Card</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                      <b-card-body style="width: 450px; height: auto;">
                        <membercard :member-id="memberInfoData.profile_card" :key="updateLoggedUserInfo"></membercard>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
              <div class="col-md-12 mb-3" v-if="subdomain == 'waterski'">

                <b-card>
                  <p class="m-0" v-if="!displayOfficialRatings">Currently, We are working. Please check later<br></p>
                  <p class="m-0"><strong>Contact </strong><a class="text-danger" href="mailto:officials@usawaterski.org">officials@usawaterski.org</a> for assistance with Official Ratings</p>
                </b-card>
              </div>
            </div>
            <div class="col-md-3 status_bar mt-4">
               <div class=" right-sidebarlink">
                 <pagesidebar
            :links="this.getSiteProps('memberdashboard.links')"
          ></pagesidebar>
               </div>
            </div>
         </div>
      </div>
      <!-- <dashboardfooter></dashboardfooter> -->

   </div>
</template>
<script>
// import navigation from "../components/Navigation";
// import sidebar from "../components/Sidebar";
// import dashboardfooter from "../components/Footer";
import pagesidebar from "../components/PageSidebar";
import axios from "axios";
import moment from "moment";
import membercard from "../profile/membercard";
import $ from "jquery";

export default {

  name: "myofficialratingandcertification",
  components: {pagesidebar, membercard},
  data() {
    return {
      exportProgress:false,
      exportProgressBtn:'',
      exportProgressExc:false,
      exportProgressExcBtn:'',
      age:0,
      prop_links: null,
      mvr:null,
      getError:false,
      driverRating:false,
      memberInfoData:[],
      ratingData: [],
      personData: [],
      ratingTblFields: [
        {key: 'division', sortable: true, label: 'Division'},
        {key: 'rating_type', sortable: true, label: 'Rating'},
        {key: 'level', sortable: true, label: 'Level'},
        {key: 'EventAbbreviation', sortable: true, label: 'Event'},
        {key: 'Effective_Date', sortable: true, label: 'Effective Date', formatter: "usdate"},
        {key: 'ExpirationDate', sortable: true, label: 'Expiration Date', formatter: (value, key, item) => {
          let expDt = moment(value);
          if(value == null) {
            return "N/A";
          }else if(moment().isAfter(expDt)){
            return '<span class="text-danger">'+expDt.format("MM/DD/YYYY")+'</span>';
          }else{
            return expDt.format("MM/DD/YYYY");
          }
        }},
        {key: "Active", sortable: false, label: 'Eligible to renew'},
      ],
      ratingSortBy: 'expiration_date',
      ratingSortDesc: 'false',
      certsData: [],
      certsTblFields: [
        {key: 'name', sortable: true, label: 'Certification'},
        {key: 'status', sortable: false, label: 'Status'},
        {key: 'data', sortable: false, label: 'Expiration Date'},
      ],
      certsSortBy: "EffectiveToDate",
      certsSortDesc: "false",
      eventName: {
        '': 'None',
        'N': 'None',
        'S': 'Slalom',
        'T': 'Trick',
        'J': 'Jump'
      },
      showLoader: true,
      eventsData:[],
      eventsTblFields:[
        { key: 'RatingType', sortable: true, label: 'Rating' },
        { key: 'SanctionedEventSanctionNumber', sortable: true, label: 'Sanction No.' },
        { key: 'SanctionedEventName', sortable: true, label: 'Event Name' },
        { key: 'SanctionedEventClass', sortable: true, label: 'Class' },
        { key: 'SanctionedEventStateCode', sortable: true, label: 'State' },
        { key: 'EventTitle', sortable: true, label: 'Event' },
        { key: 'DivisionCode', sortable: true, label: 'Division' },
        { key: 'ParticpationDate', sortable: true, label: 'Date', formatter: "usdate" },
        { key: 'EventPosition', sortable: true, label: 'Position' },
        { key: 'ParticipationCredits', sortable: true, label: 'Credits' },
      ],
      eventsSortBy:'ParticpationDate',
      eventsSortDesc:'true',
    }
  },
  methods:{
    downloadOfficials(type, personId){
      let url = this.basePath + 'api/official/'+type+'/'+personId;
      this.exportProgress = true;
      this.exportProgressBtn = 'disabled';
      axios.get(url, {headers:this.memberHeaders, responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = personId+"_"+type+'.pdf';
            link.click();
            link.remove();
            this.exportProgress = false;
            this.exportProgressBtn = '';
          }).catch((error)=>{
        console.log("error download");
      });
    },

    downloadOfficialsAsExcel(type, personId){
      var self = this;
      this.exportProgressExc = true;
      this.exportProgressExcBtn = 'disabled';
        let url = this.basePath + 'api/official-as-excel/'+type+'/'+personId;
            $.ajax({
                url: url,
                type: 'get',
                headers:this.memberHeaders,
                "success": function(res, status, xhr) {
                  if(res.file_url != undefined) window.open(res.file_url, '_blank');
                    self.exportProgressExc = false;
                    self.exportProgressExcBtn = '';
                },
                error: function(err){
                    console.log(err);
                    self.exportProgressExc = false;
                    self.exportProgressExcBtn = '';
                }
            })
    },
    loadOfficialData(){
      if(this.memberInfoData.person_id!=''){
        axios.get(this.basePath + 'api/member/officialratings/'+this.memberInfoData.person_id, {headers: this.memberHeaders})
            .then(function (response) {
              if(response.data.status!='error') {
                const resp = response.data.data;
                if(response.data.message!="Unauthorized parameter UserId from this token"){
                  this.ratingData = resp.rating;
                  this.certsData = resp.certs;
                  this.personData = resp.personInfo;
                  this.eventsData = resp.events;
                  // this.driverRating = resp.driverRating;
                  this.member = resp.member;
                  this.age = this.getAge(resp.member.personal_details.dob);
                  this.mvr = resp.mvr;
                }
                else{
                  this.getError = true;
                }
              }else{
                this.personData.Email = response.data.message;
              }
              this.showLoader=false;
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    getAge(birth_date) {
                if(birth_date == null) return 0;
                let birth_date_arr = birth_date.split('/');
                let today_date = new Date();
                let today_year = today_date.getFullYear();
                let today_month = today_date.getMonth();
                let today_day = today_date.getDate();
                let age = today_year - birth_date_arr[2];

                if ( today_month < (birth_date_arr[0] - 1))
                {
                    age--;
                }
                if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1]))
                {
                    age--;
                }
                return age;
    },
  },
  mounted() {
    this.prop_links = this.getSiteProps("memberdashboard.links");
    this.memberInfoData = JSON.parse(localStorage.getItem('member'));
    this.loadOfficialData();
  }
}
</script>
<style>
.card .overlay{
  background: none;
}
</style>